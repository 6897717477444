import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import SecondaryLayout from "../components/layout/secondary/secondary";
import CategoryBanner from "../components/category-banner/category-banner";
import ProductGrid from "../components/product-grid/product-grid";
import useWindowSize from "../hooks/useWindowSize";

const CategoryPage = ({ data, pageContext, ...props }) => {
  const windowSize = useWindowSize();

  const { image, products } = data.shopifyCollection;

  const productsType = pageContext?.handle;

  const subCate = pageContext.categories.filter(
    (e) => e.node?.parent_category?._meta.uid == pageContext.handle && e
  );
  const self = pageContext.categories.find(
    (e) => e.node?._meta.uid == pageContext.handle && e
  );
  const title = self?.node?.title[0]?.text;

  return (
    <SecondaryLayout categories={pageContext.categories}>
      <SEO title={title} />
      <CategoryBanner
        title={title}
        bgImage={
          windowSize >= 768
            ? self?.node?.image?.url
            : self?.node?.category_image_mobile?.url
        }
      />
      <ProductGrid
        subCate={subCate}
        id="category"
        products={products}
        isCategoryProduct={true}
      />
      <div style={{ marginBottom: 80 }} />
    </SecondaryLayout>
  );
};

export const query = graphql`
  query($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      image {
        id
        src
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      products {
        id
        title
        handle
        createdAt
        shopifyId
        availableForSale
        variants {
          id
          price
          priceV2 {
            amount
            currencyCode
          }
          shopifyId
          selectedOptions {
            name
            value
          }
          title
          availableForSale
        }
        images {
          id
          originalSrc
          localFile {
            childImageSharp {
              fluid(maxWidth: 910, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    prismic {
      allCategorys(first: 1000, where: {}, uid: $handle) {
        edges {
          node {
            image
            category_image_mobile
            title
            _meta {
              id
            }
          }
        }
      }
    }
  }
`;

export default CategoryPage;
