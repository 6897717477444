/** @jsx jsx */
import Img from "gatsby-image";
import useWindowSize from "../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { Box, Heading, Text, jsx } from "theme-ui";
import styles from "./category-banner.style";
import placeholder from "../../images/category/placeholder.jpg";
type PropsType = {
  title?: string;
  subtitle?: string;
  bgImage?: string;
};

const CategoryBanner: React.FC<PropsType> = ({
  title,
  subtitle,
  bgImage,
}: any) => {
  const [bg, setbg] = useState(404);
  useEffect(() => {
    (async function exec() {
      const bgBanner = await fetch(bgImage);
      setbg(bgBanner.status);
    })();
  }, []);

  const windowSize = useWindowSize();
  return (
    <Box
      style={{
        backgroundImage: `url(${(bg && bg == 200 && bgImage) || placeholder})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        height: windowSize > 767 ? undefined : 300,
      }}
      as="section"
      sx={styles.wrapper}
    >
      {/* {bg ? (
        <img
          style={{ width: "100%", position: "absolute" }}
          src={
            bg == 200
              ? bgImage
              : require('../../images/category/placeholder.jpg')
          }
        />
      ) : null} */}
      <Box as="header" sx={styles.bannerContent}>
        <Heading
          style={{ textShadow: "1px 1px #00000063", color: "white" }}
          as="h1"
        >
          {title && title.replace(/-/g, " ")}
        </Heading>
        {subtitle && <Text as="p">{subtitle}</Text>}
      </Box>
    </Box>
  );
};

export default CategoryBanner;
