/** @jsx jsx */
import { StaticQuery, graphql } from "gatsby";
import { Box, Text, jsx, Button } from "theme-ui";
import { IoIosClose } from "react-icons/io";
import TreeMenu from "../../../tree-menu/tree-menu";
import styles from "./sidebar.style";
import useWindowSize from "../../../../hooks/useWindowSize";
import { AiOutlineUser } from "react-icons/ai";

const menuStaticQuery = graphql`
  query {
    prismic {
      allCategorys(first: 1000) {
        totalCount
        edges {
          cursor
          node {
            _meta {
              uid
            }
            title
            icon
            parent_category {
              ... on PRISMIC_Category {
                title
                _meta {
                  uid
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const getMenuData = (categories) => {
  const data: any = [];
  //fill up first parent
  categories.forEach((category: any) => {
    if (!category?.node?.parent_category) {
      const uid = category?.node?._meta.uid;
      const text = category?.node?.title[0]?.text;
      const icon = category?.node?.icon;
      if (uid && text) {
        data.push({ path: uid, title: text, icon });
      }
    }
  });
  //fill up child
  categories.forEach((category: any) => {
    if (category?.node?.parent_category) {
      const parent = category?.node?.parent_category?._meta?.uid;
      const uid = category?.node?._meta.uid;
      const text = category?.node?.title[0]?.text;
      const index = data.findIndex((item: any) => item.path === parent);
      const icon = category?.node?.icon;

      if (index > -1) {
        if (data[index]?.submenu && data[index]?.submenu?.length) {
          data[index].submenu.push({ path: uid, title: text, icon });
        } else {
          data[index].submenu = [];
          data[index].submenu.push({ path: uid, title: text, icon });
        }
      }
    }
  });
  return data;
};

const Sidebar: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
  return (
    <StaticQuery<GatsbyTypes.Query>
      query={`${menuStaticQuery}`}
      render={(data: any) => {
        const firstData = data?.prismic || false;

        // const lastData = false;
        let categories: any = [];
        if (firstData) {
          categories = [...firstData?.allCategorys.edges];
        }
        const menuData = getMenuData(categories);
        return <SideBarInnerContent onClose={onClose} menuData={menuData} />;
      }}
    />
  );
};

const SideBarInnerContent: React.FunctionComponent = ({
  onClose,
  menuData,
}) => {
  const windowSize = useWindowSize();

  return (
    <div style={{ height: "100%" }}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.header}>
          <Text as="p" sx={styles.text}>
            All Categories
          </Text>
          <Button variant="text" onClick={onClose}>
            <IoIosClose />
          </Button>
        </Box>
        <TreeMenu items={menuData} />
      </Box>

      {windowSize < 1020 && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            left: 0,
            padding: "13px 20px",
            flexDirection: "row",
            background: "#0b984f",
          }}
        >
          <a
            href={`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/account`}
            rel="noopener noreferrer"
            target="_blank"
            title="My account"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "white",
              fontSize: 14,
            }}
          >
            <AiOutlineUser
              style={{ height: "22px", width: "22px", paddingBottom: 4 }}
            />
            <span style={{ padding: "0px 12px" }}>My Account</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
