const styles = {
  wrapper: {
    display: "inline-flex",
    alignItems: "center",
    a: {
      display: "flex",
      transition: "all 0.2s ease",
      ":hover": {
        opacity: 0.89,
      },
    },
  },
  logo: {
    maxWidth: '200px !important',
    alignSelf: 'center'
  },
};

export default styles;
