/** @jsx jsx */
import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, jsx } from "theme-ui";
import { SearchContext } from "../../../provider/search-provider";
import useFreezeBodyScroll from "../../../hooks/useFreezeBodyScroll";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";
import Footer from "./footer/footer";
import styles from "./secondary.style";
import { animated, useSpring } from "react-spring";
// import CartStatus from "components/cart";
import CartStatus from "../../cart";

const SecondaryLayout: React.FunctionComponent = ({ children, categories }) => {
  const { isSearched } = useContext<any>(SearchContext);
  useFreezeBodyScroll(isSearched);
  const [cartBar, setCartBar] = useState(false);
  const cartBtnProps = useSpring({
    config: { mass: 1, tension: 500, friction: 48 },
    bottom: cartBar ? 87 : 30,
  });

  return (
    <Flex as="main" sx={styles.main}>
      <Box as="aside" sx={styles.sidebar}>
        <Sidebar categories={categories} />
      </Box>
      <Box sx={styles.wrapper}>
        <Header />
        <Box sx={styles.content}>{children}</Box>
        <animated.div
          style={{ ...cartBtnProps, position: "relative", zIndex: 9999 }}
        >
          <CartStatus btnProps={cartBtnProps} />
        </animated.div>
        <Footer />
      </Box>
    </Flex>
  );
};

export default SecondaryLayout;
