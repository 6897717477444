import React, { useState, useEffect, useContext, useCallback } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import useWindowSize from "../../hooks/useWindowSize";
import { CartContext } from "../../provider/cart-provider";
import { LocalCartContext } from "../../provider/local-cart-provider";
import { motion } from "framer-motion";
import ModalResponsive from "../modal-responsive";
import Store from "../../utils/store";

type PropsType = {
  price: any;
  title: string;
  thumbnail: any;
  variants: any;
  shopifyId: string;
  prevPrice?: string;
  availableForSale: boolean;
  listView?: boolean;
  src: string;
};

const ProductCard: React.FC<PropsType> = (product) => {
  const {
    title,
    price,
    variants,
    variants: [initialVariant],
    thumbnail,
    prevPrice,
    listView = false,
  } = product;

  const {
    store: { client },
  } = useContext(CartContext);
  const { products, add, update } = useContext(LocalCartContext);

  const cart = products.filter((e) =>
    product.variants.find((item) => item.shopifyId === e.variantId)
  );

  const [quantity, setquantity] = useState(0);

  useEffect(() => {
    if (cart.length > 0) {
      setquantity(cart.reduce((a, c) => a + c.quantity, 0));
    }
  }, [cart]);

  const handleAddToCart = (index) => {
    // check if variant is default

    const item = {
      title: title + " " + product.variants[index].selectedOptions[0].value,
      thumbnail,
      quantity: 1,
      price: product.variants[index].priceV2.amount,
      currency: product.variants[index].priceV2.currencyCode,
      variantId: product.variants[index].shopifyId,
    };
    add(item);
  };

  const handleQuantityChange = (quantity: number, variantId) => {
    update(variantId, quantity);
  };

  const [modal, setmodal] = useState(false);
  const closeModal = useCallback(() => {
    setmodal(false);
  }, []);

  return (
    <div>
      {/* <ModalResponsive
          onAttemptClose={()=>setmodal(false)}
          isOpen={modal}
        /> */}
      {modal && (
        <Modal
          cart={cart}
          addtoCart={handleAddToCart}
          removefromCart={handleQuantityChange}
          close={closeModal}
          data={product}
        />
      )}
      <motion.div
        style={{ cursor: "pointer" }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.3 }}
        sx={{
          maxWidth: 296,
          // padding: 20,
          // border: "1px solid #757575",
          borderRadius: 20,
        }}
      >
        <img
          onClick={() => {
            setmodal(true);
          }}
          style={{
            width: "100%",
            height: "calc( 20vh + 7vw)",
            objectFit: "contain",
            background: "white",
            // boxShadow: "rgb(136 136 136 / 7%) 0px 0px 20px 0px",
          }}
          src={
            thumbnail.src ||
            "https://cdn-5ec40373c1ac18016c052912.closte.com/wp-content/uploads/2020/02/no-image-found-360x260-2.png"
          }
          alt={title}
        />
        <h4
          style={{ margin: 0, fontWeight: "bolder", marginBottom: "5px" }}
          className="css-h4"
        >
          From Rs.{" "}
          {
            product.variants.sort((a, b) => a.amount - b.amount)[0].priceV2
              .amount
          }
        </h4>
        <p
          style={{ margin: 0, opacity: 0.9, lineHeight: "1.4" }}
          className="css-p"
        >
          {title}
        </p>
      </motion.div>
    </div>
  );
};

export default ProductCard;

function Modal({ data, close, addtoCart, cart, removefromCart }) {
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const windowSize = useWindowSizeHeight();
  const [effect, setEffect] = useState("scale(0)");
  const [description, setdescription] = useState(false);
  useEffect(() => {
    setSelectedVariant(data.variants[0]);
    setSelectedIndex(0);
    setEffect("scale(1)");
  }, []);

  useEffect(() => {
    Store.add_item("close_product_modal", close);
  }, []);

  const windowSize = useWindowSize();

  return (
    <div
      style={{ transform: effect, transition: "transform 0.4s" }}
      className="css-modalMain"
      id="modal-container"
    >
      <div className="css-dryepg edf5mpm1">
        <div style={{ overflow: "hidden" }} className="css-181xfqa edf5mpm0">
          <div
            //  style={{ overflow: "hidden" }}
            className="css-18kg8c9 e17i4t1w1"
          >
            <div className="css-fqsp5d e1qfcze90">
              <div>
                <div
                  className="css-hgvxx6 e1qfcze91"
                  style={{
                    backgroundImage: `url("${
                      data.thumbnail
                        ? data.thumbnail.src + "?"
                        : "https://cdn-5ec40373c1ac18016c052912.closte.com/wp-content/uploads/2020/02/no-image-found-360x260-2.png"
                    }")`,
                    opacity: 1,
                    backgroundPosition: "center",
                    backgroundSize: "80%",
                  }}
                />
              </div>
              <div className="css-f85l49 e1qfcze92" />
              <div
                onClick={() => {
                  setTimeout(() => {
                    close();
                  }, 1000);
                  setEffect("scale(0)");
                }}
                className="css-18r4k63 e17i4t1w11"
              >
                <svg
                  width={24}
                  height={24}
                  fill="#343434"
                  viewBox="0 0 24 24"
                  style={{ opacity: 0.5 }}
                >
                  <defs>
                    <path
                      id="icon-close_svg__a"
                      d="M0 1.5L1.5 0 8 6.5 14.5 0 16 1.5 9.5 8l6.5 6.5-1.5 1.5L8 9.5 1.5 16 0 14.5 6.5 8z"
                    />
                  </defs>
                  <use
                    xlinkHref="#icon-close_svg__a"
                    transform="translate(4 4)"
                  />
                </svg>
              </div>
            </div>
            <div className="css-ojqyia e17i4t1w3">
              <div className="css-1x8b7q3 e17i4t1w4">
                <div
                  onClick={() => {
                    setTimeout(() => {
                      close();
                    }, 1000);
                    setEffect("scale(0)");
                  }}
                  className="css-1lg9a33 e17i4t1w10"
                >
                  <svg
                    width={24}
                    height={24}
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    style={{ opacity: 0.5 }}
                  >
                    <defs>
                      <path
                        id="icon-close_svg__a"
                        d="M0 1.5L1.5 0 8 6.5 14.5 0 16 1.5 9.5 8l6.5 6.5-1.5 1.5L8 9.5 1.5 16 0 14.5 6.5 8z"
                      />
                    </defs>
                    <use
                      xlinkHref="#icon-close_svg__a"
                      transform="translate(4 4)"
                    />
                  </svg>
                </div>

                <div className="css-modalBottomContainer">
                  {/* Title & Price */}
                  <div
                    className={"ScrollHidden"}
                    style={{ overflow: windowSize > 700 && "auto" }}
                  >
                    <div
                      className="css-modalTitle"
                      style={{ marginBottom: "15px" }}
                    >
                      <span
                        style={{
                          marginBottom: "0px",
                          fontFamily: "axiforma",
                          fontSize: "22px",
                          color: "#343434",
                        }}
                      >
                        {data.title}
                      </span>
                      <span
                        style={{
                          marginBottom: "0px",
                          fontFamily: "axiforma",
                          fontSize: "20px",
                          color: "#343434",
                        }}
                      >
                        Rs. {selectedVariant.price}
                      </span>
                    </div>
                    <div className="css-modalVariantContainer">
                      {data.variants.map((variant, i) => {
                        const cartInfo = cart.find(
                          (a) => a.variantId == variant.shopifyId
                        );
                        return (
                          <div
                            key={i + ""}
                            className="css-modalVairant"
                            style={
                              selectedVariant.shopifyId == variant.shopifyId
                                ? {
                                    backgroundColor: "#00BD51",
                                    color: "#fff",
                                    cursor: "pointer",
                                  }
                                : { cursor: "pointer" }
                            }
                            onClick={() => {
                              setSelectedVariant(variant);
                              setSelectedIndex(i);
                            }}
                          >
                            {variant.title}
                          </div>
                        );
                      })}
                    </div>

                    <div
                      style={{
                        WebkitLineClamp:
                          windowSize > 1059
                            ? undefined
                            : description
                            ? undefined
                            : 3,
                        position: "relative",
                      }}
                      className="css-modalProductDescription"
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                          boxShadow:
                            windowSize > 1059
                              ? undefined
                              : description
                              ? undefined
                              : "inset 0px -11px 10px #ffffff",
                        }}
                      />

                      {data.description
                        ? data.description
                        : `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut massa ex, lacinia vel quam sit amet, condimentum mollis ipsum. Maecenas pulvinar libero vitae lectus commodo, vitae congue enim euismod. Maecenas laoreet nisl id enim porta interdum. Nulla pharetra, est nec euismod rhoncus, quam ligula aliquam ex, et facilisis risus nulla eu nulla. Nulla eu sagittis enim, ut vestibulum risus. Nam fermentum mauris orci, ac euismod neque elementum id. Aenean ante risus, tincidunt at viverra in, egestas ut tellus. Quisque quis eros mauris. Integer aliquet porta justo sit amet pellentesque. Integer vitae semper ipsum.`}
                    </div>
                    {windowSize > 1059 ? undefined : description ? (
                      // <span
                      //   style={{
                      //     float: "right",
                      //   }}
                      //   onClick={() => setdescription(false)}
                      // >
                      //   Hide Story ...
                      // </span>
                      <div></div>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          // float: "center",
                          // color: "red",
                        }}
                      >
                        <span
                          style={{ opacity: 0.7 }}
                          onClick={() => setdescription(true)}
                        >
                          Read more
                        </span>
                      </div>
                    )}
                    <div style={{ height: 70 }} />
                  </div>

                  <div
                    style={{
                      position: windowSize < 700 && "fixed",
                      bottom: windowSize < 700 && 0,
                      left: windowSize < 700 && 0,
                      right: windowSize < 700 && 0,
                      padding: windowSize < 700 && "10px 20px",
                      backgroundColor: windowSize < 700 && "#fff",
                    }}
                  >
                    {/* Variants */}
                    <div
                      style={{
                        cursor: "pointer",
                        marginTop: 20,
                        background: "#00bd51",
                        color: "white",
                      }}
                      className="css-button"
                      onClick={() => addtoCart(selectedIndex)}
                    >
                      <AiOutlineShoppingCart className="css-modalShoppingCart" />
                      <span>ADD TO CART</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
