/** @jsx jsx */
import { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Box, Grid, Heading, jsx, Button } from "theme-ui";
import ProductCard from "../product-card/product-card";
import Loader from "../loader/loader";
import styles from "./all-products.style";

const productStaticQuery = graphql`
  query {
    allShopifyProduct {
      edges {
        node {
          id
          title
          handle
          createdAt
          shopifyId
          availableForSale
          variants {
            id
            price
            priceV2 {
              amount
              currencyCode
            }
            shopifyId
            selectedOptions {
              name
              value
            }
            title
            availableForSale
          }
          images {
            id
            originalSrc
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          description
        }
      }
    }
    prismic {
      allCategorys(first: 1000) {
        edges {
          node {
            image
            icon
            featured
            parent_category {
              ... on PRISMIC_Category {
                _meta {
                  id
                }
              }
            }
            description
            title
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;

const AllProducts = () => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${productStaticQuery}`}
    render={(data: any) => {
      const [visible, setVisible] = useState(20);
      const [loading, setLoading] = useState(false);
      const products = data?.allShopifyProduct?.edges;
      const categories = data?.prismic?.allCategorys.edges;

      const handleLoadMore = () => {
        setLoading(true);
        setTimeout(() => {
          setVisible(visible + 12);
          setLoading(false);
        }, 1000);
      };

      const getPrice = (price: any) =>
        Intl.NumberFormat(undefined, {
          currency: price.currencyCode,
          minimumFractionDigits: 2,
          style: "currency",
        }).format(parseFloat(price && price.amount ? price.amount : 0));

      return (
        <Box id="allProducts" sx={styles.wrapper}>
          <CategoriesSlider
            categories={categories.filter((e) => !e?.node?.parent_category)}
          />

          <div style={{ margin: "10px 0px 30px 0" }}>
            <Heading sx={{}} as="h3">
              All Products
            </Heading>
          </div>
          <Grid sx={styles.productGrid}>
            {products &&
              products.slice(0, visible).map((product: any) => {
                const {
                  id,
                  title,
                  shopifyId,
                  variants,
                  availableForSale,
                  images: [firstImage],
                  variants: [firstVariant],
                  description,
                } = product.node;
                return (
                  <ProductCard
                    key={id}
                    title={title}
                    shopifyId={shopifyId}
                    availableForSale={availableForSale}
                    price={getPrice(firstVariant?.priceV2)}
                    thumbnail={
                      firstImage?.localFile?.childImageSharp?.fluid || {
                        src: firstImage?.originalSrc,
                      }
                    }
                    variants={variants}
                    src={product?.node?.images[0]?.originalSrc}
                    description={description}
                  />
                );
              })}
          </Grid>
          <div style={{ height: "48px" }}></div>
          {visible < products?.length && (
            <Button
              sx={styles.loadMoreBtn}
              onClick={handleLoadMore}
              className="css-button1"
            >
              {loading ? <Loader /> : "Show more"}
            </Button>
          )}
        </Box>
      );
    }}
  />
);

export const CategoriesSlider = ({ categories }) => {
  if (!categories || categories.length == 0) {
    return <div />;
  }
  return (
    <div
      style={{
        marginBottom: "20px",
        width: "100%",
        height: 80,
        position: "relative",
      }}
    >
      <div id="ii" className="category-slider-inner ScrollHidden">
        {categories.map((e, i) => {
          const BgImage =
            e?.node?.image?.url ||
            require("../../images/category/placeholder.jpg");

          return (
            // e.node.title[0].text
            <div
              key={i.toString()}
              style={{
                minWidth: 200,
                height: 80,
                backgroundSize: "cover",
                backgroundImage: "url(" + BgImage + ")",
                marginRight: 10,
                borderRadius: 10,
                position: "relative",
                marginBottom: 10,
              }}
            >
              <Link to={"/modern/collection/" + e.node._meta.uid}>
                <div
                  style={{
                    position: "absolute",
                    justifyItems: "center",
                    alignItems: "center",
                    display: "flex",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 10,
                    padding: "0 20px",
                    backgroundColor: "rgba(0,0,0,0.5)",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {e.node.title[0].text}
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
        <div></div>
      </div>
    </div>
  );
};

export default AllProducts;
