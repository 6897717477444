/** @jsx jsx */
import { Link, StaticQuery, graphql } from 'gatsby';
import { Box, Image, jsx } from 'theme-ui';
import styles from './logo.style';
import useWindowSize from '../../hooks/useWindowSize';


type PropsType = {
	imgSrc?: string;
	imgAlt?: string;
	path?: string;
};

const logoStaticQuery = graphql`
	query {
		prismic {
			allCommons {
				edges {
					node {
						logo
						logoweb
					}
				}
			}
		}
	}
`;

const Logo: React.FC<PropsType> = ({ imgSrc, imgAlt, path = '/' }) => {
	const windowSize = useWindowSize();
	
	return (
		<StaticQuery<GatsbyTypes.Query>
			query={`${logoStaticQuery}`}
			render={({ prismic }) => {
				const logo = prismic?.allCommons?.edges[0].node?.logo;
				const logoWeb = prismic?.allCommons?.edges[0].node?.logoweb;

				
				return (
					<Box className="site-logo" sx={styles.wrapper}>
						<Link to={path}>
							<Image
								sx={styles.logo}
								src={(windowSize > 768) ? logoWeb.url : logo.url}
								alt={imgAlt ? imgAlt : logo.url}
							/>
						</Link>
					</Box>
				);
			}}
		/>
	);
};

export default Logo;
